window.$ = window.jQuery = require('jquery');
var jQuery = window.jQuery;
require('jquery-migrate');
import 'bootstrap';
require( 'jquery.stellar/jquery.stellar' );
require('owl.carousel');
require('slick-carousel');
require('./plugins.js');
require('@fortawesome/fontawesome-free');
window.AOS = require ('aos');

window.site = {
  init: function() {
    this.initStellar();
    this.initCarousel();
    this.initCarousel2();
    $(window).bind('scroll',window.site.scroll);
    this.initParallax();
    this.initAOS();
    this.animateRandom();
  },
  animateRandom:function () {

      setInterval(function() {
        var $elms = $('[data-animate="true"]:not([data-origclass])');
        var numElms = $elms.length;

        var random = Math.floor(Math.random() * numElms);


        var $targetElm = $($elms[random]);
        var animateClass = $targetElm.data('animateclass');
        var beforeClass = 'before-' + animateClass;

        $targetElm.removeClass(beforeClass).addClass(animateClass);


        var $isAnimated = $('[data-origclass]');
        if($isAnimated.length>0) {

        	console.log($isAnimated);
        	$isAnimated.each(function(a,b) {
        		$(b).removeClass($(b).attr('data-animateclass')).addClass($(b).attr('data-origclass')).removeAttr('data-origclass');
            });

        }

        $targetElm.attr('data-origclass',beforeClass);

    },3000);

},

  initStellar: function() {
    $(window).stellar({
      responsive: false,
      parallaxBackgrounds: true,
      parallaxElements: true,
      horizontalScrolling: false,
      hideDistantElements: false,
      scrollProperty: 'scroll'
    });
  },
  initAOS: function() {
    AOS.init();
  },
  initParallax: function() {
    var elms = $('.parallax-window');
    elms.each(function(a,b) {
    console.log($(b).data('image-src'));
    $(b).css('background-position','89% -273px');
    $(b).css('background-image','url(' + $(b).data('image-src') + ')');//.parallax('90%',0.4);

    var offset = $(b).data('parallax-offset');
    var speed = $(b).data(' parallax-speed');
    if(!offset) { offset='75%'; }
    if(!speed) { speed = 0.3; }
    $(b).parallax(offset,speed,true,-500);
    });
  },
  initCarousel: function() {
    $('.carousel .carousel-item').each(function(){
      var next = $(this).next();
      if (!next.length) {
      next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));

      for (var i=0;i<99;i++) {
          next=next.next();
          if (!next.length) {
            next = $(this).siblings(':first');
          }

          next.children(':first-child').clone().appendTo($(this));
        }
    });
  },
  initCarousel2: function() {
    $('.home-slider').owlCarousel({
	    loop:true,
	    autoplay: false,
	    margin:0,
	    animateOut: 'fadeOut',
	    animateIn: 'fadeIn',
	    nav:true,
	    autoplayHoverPause: false,
	    items: 1,
	    navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
	    responsive:{
	      0:{
	        items:1,
	        nav:false
	      },
	      600:{
	        items:1,
	        nav:false
	      },
	      1000:{
	        items:1,
	        nav:true
	      }
	    }
	   });
     $('.page-slider').owlCarousel({
 	    loop:true,
 	    autoplay: false,
 	    margin:0,
 	    animateOut: 'fadeOut',
 	    animateIn: 'fadeIn',
 	    nav:true,
 	    autoplayHoverPause: false,
 	    items: 1,
 	    navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
 	    responsive:{
 	      0:{
 	        items:1,
 	        nav:false
 	      },
 	      600:{
 	        items:1,
 	        nav:false
 	      },
 	      1000:{
 	        items:1,
 	        nav:true
 	      }
 	    }
 	   });
     $('.travelpage-slider').owlCarousel({
 	    loop:false,
 	    autoplay: false,
 	    margin:0,
 	    animateOut: 'fadeOut',
 	    animateIn: 'fadeIn',
 	    nav:false,
 	    autoplayHoverPause: false,
 	    items: 1,
 	    navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
 	    responsive:{
 	      0:{
 	        items:1,
 	        nav:false
 	      },
 	      600:{
 	        items:1,
 	        nav:false
 	      },
 	      1000:{
 	        items:1,
 	        nav:true
 	      }
 	    }
 	   });
     $('.hotel-slider').owlCarousel({
       loop:false,
       autoplay: false,
       margin:0,
       animateOut: 'fadeOut',
       animateIn: 'fadeIn',
       nav:false,
       autoplayHoverPause: false,
       items: 1,
       navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
       responsive:{
         0:{
           items:1,
           nav:false
         },
         600:{
           items:1,
           nav:false
         },
         1000:{
           items:1,
           nav:true
         }
       }
      });
     $('.traveloffer').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        cssEase: 'ease',
        pauseOnHover: true,
        autoplay: true,
        autoplaySpeed: 300000,
        prevArrow: '<button type="button" class="slick-prev">Vorige</button>',
        nextArrow: '<button type="button" class="slick-next">Volgende</button>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });

		$('.carousel2').owlCarousel({
			center: true,
			loop: true,
			items:1,
			margin: 30,
			stagePadding: 0,
			nav: true,
			navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
			responsive:{
				0:{
					items: 1
				},
				600:{
					items: 2
				},
				1000:{
					items: 4
				}
			}
		});

    $('.carousel-regio').owlCarousel({
      loop: false,
      items:1,
      margin: 30,
      stagePadding: 10,
      nav: true,
      navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
      responsive:{
        0:{
          items: 1
        },
        600:{
          items: 1
        },
        1000:{
          items: 1
        }
      }
    });


		$('.carousel1').owlCarousel({
			loop: false,
			items:1,
			margin: 30,
			stagePadding: 10,
			nav: true,
			navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
			responsive:{
				0:{
					items: 1
				},
				600:{
					items: 2
				},
				1000:{
					items: 4
				}
			}
		});

		$('.carousel-engine').owlCarousel({
			loop: false,
			items:1,
			margin: 30,
			stagePadding: 0,
			nav: false,
			navText: ['<span class="icon-arrow_back">', '<span class="icon-arrow_forward">'],
			responsive:{
				0:{
					items: 1
				},
				600:{
					items: 2
				},
				1000:{
					items: 4
				}
			}
		});
  },
  scroll: function() {
    var $w = $(this),
        st = $w.scrollTop(),
        navbar = $('.ftco_navbar'),
        sd = $('.js-scroll-wrap');

    if (st > 150) {
      if ( !navbar.hasClass('scrolled') ) {
        navbar.addClass('scrolled');
      }
    }
    if (st < 150) {
      if ( navbar.hasClass('scrolled') ) {
        navbar.removeClass('scrolled sleep');
      }
    }
    if ( st > 350 ) {
      if ( !navbar.hasClass('awake') ) {
        navbar.addClass('awake');
      }

      if(sd.length > 0) {
        sd.addClass('sleep');
      }
    }
    if ( st < 350 ) {
      if ( navbar.hasClass('awake') ) {
        navbar.removeClass('awake');
        navbar.addClass('sleep');
      }
      if(sd.length > 0) {
        sd.removeClass('sleep');
      }
    }
  },

};


$(function() {
  window.site.init();
})
